import React, {useState, useEffect} from 'react';
import Form from 'react-bootstrap/Form';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Button from 'react-bootstrap/Button';

export default function ProductReturnReasons() {

  const [selectedReasons, setSelectedReasons] = useState([]);

  const reasons = [
    'Product was damaged',
    'Received wrong item',
    'Item did not match description',
    'Quality was not as expected',
    'No longer needed',
    'Other'
  ]

  const handleReasonChange = (reason) => {
    setSelectedReasons((prevSelected) => {
      if(prevSelected.includes(reason)) {
        return prevSelected.filter((r) => r !== reason);
      } else {
        return [...prevSelected, reason];
      }
    });
  };

  return (
    <div className='bg-body-secondary p-3'>
      <div className='bg-light p-3 mx-2 shadow'>
        <p className='fs-6 fw-semibold'>
          Can you tell us the reason for product return?
        </p>
        <div>
          {reasons.map((reason, index) => (
            <div key={index} className='form-check'>
              <input
                className='form-check-input'
                type='checkbox'
                value={reason}
                id={`reason-${index}`}
                checked={selectedReasons.includes(reason)}
                onChange={() => handleReasonChange(reason)}
              />
              <label className='form-check-label' htmlFor={`reason-${index}`}>
                {reason}
              </label>
            </div>
          ))}
        </div>
        <div className='shadow'>
          <FloatingLabel
            controlId='floatingTextArea'
            label='Please enter your reasons.'
            className='mb-2'
          >
            <Form.Control as="textarea" placeholder='Please Enter any reasons you might have.' />
          </FloatingLabel>
        </div>
        <div>
          <Button variant='primary' className='w-100'>Continue to Return</Button>
        </div>
      </div>
    </div>
  )
}
