import axios from "axios";
const axiosClient = axios.create({
    baseURL: 'http://localhost:5000/',
    timeout: 80000,
    withCredentials: true,
    credentials: 'include',
    
})
//52.203.121.35
//https://api.healthhepta.com
// http://localhost:8081
// https://healthhepta-versel-fsea.vercel.app

export default axiosClient;